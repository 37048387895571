import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import {withTranslation} from "react-i18next";
import CallActionService from "./callaction/CallActionService";

class PismeniPrijevod extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        this.scrollToElement();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.hash !== prevProps.location.hash) {
            this.scrollToElement();
        }
    }

    scrollToElement = () => {
        const { hash } = window.location;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }

    render(){
        const { t, i18n } = this.props;
        let tr = t('mentorship',{returnObjects: true})
        let lang = i18n.language
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle={tr['pageTitle']} />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <div className={`rn-page-title-area pt--120 pb--190 bg_image bg_image--40-${lang} bg_image-${lang}`}  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title text-white">{tr['title']}</h2>
                                    {/*<p></p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center"  id="mentorstvo">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-10.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{tr['mentorship']['title']}</h4>
                                                    <p>{tr['mentorship']['question']}</p>
                                                    <ul className="liststyle">
                                                        {tr['mentorship']['questionList'].map((val, i) => (
                                                            <li key={i}>{val}</li>
                                                        ))}
                                                    </ul>
                                                    {tr['mentorship']['afterQuestions'].map((val, i) => (
                                                        <p key={i} dangerouslySetInnerHTML={{__html:val}}></p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                <CallActionService actionKey='mentorship'/>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default withTranslation()(PismeniPrijevod);