import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import {FiChevronUp, FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";
import {withTranslation} from "react-i18next";

class Contact extends Component {

    render() {
        const {t, i18n} = this.props;
        let tr = t('contact', {returnObjects: true});
        let lang = i18n.language
        return (
            <React.Fragment>
                <PageHelmet pageTitle={tr['pageTitle']}/>

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <div className={`rn-page-title-area pt--120 pb--190 bg_image bg_image--40-${lang} bg_image-${lang}`}
                     data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title text-white">{tr['title']}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5 flex-grow-1">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{tr['callUs']}</h4>
                                        <p><a href="tel:+38598406413">+385 98 406 413</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{tr['sendUsAnEmail']}</h4>
                                        <p><a
                                            href="mailto:lidija.simunic.mesic@gmail.com">lidija.simunic.mesic@gmail.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{tr['location']}</h4>
                                        <p>Zagreb, Dunjevac 15 B</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                    <div className={"pb--90"}></div>
                </div>
                {/* End Contact Top Area  */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>

            </React.Fragment>
        )
    }
}

export default withTranslation()(Contact);