import React, {Component, Fragment} from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import {slideSlick} from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import {FiCast, FiChevronUp, FiLayers, FiUsers} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import {withTranslation} from "react-i18next";

const getIcon = (icon) => {
    switch (icon) {
        case 'FiCast':
            return <FiCast />;
        case 'FiLayers':
            return <FiLayers />;
        case 'FiUsers':
            return <FiUsers />;
        default:
            return <FiCast />;
    }
}

class CorporateBusiness extends Component{
    constructor (props) {
        super(props)
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { t, i18n } = this.props;
        let translation = t('homepage', {returnObjects: true})
        let lang = i18n.language


        return(
            <Fragment> 
                <Helmet pageTitle="LSM prijevodi" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {translation['banner'].map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image bg_image-${lang} ${value.bgImage}-${lang}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                    <div className="pb--120"></div>
                                                    {value.description ? <p className={`description`}>{value.description}</p> : ''}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/*<div className="rn-testimonial-area bg_color--1 ptb--120">*/}
                {/*    <div className="container">*/}
                {/*        <TestimonialOne />*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">{translation['servicesDesc']['title']}</h2>
                                    <p>{translation['servicesDesc']['desc']}</p>
                                    <div className="service-btn">
                                        <a className="btn-transparent rn-btn-dark" href="/kontakt"><span className="text">{translation['servicesDesc']['button']}</span></a>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {translation['servicesDesc']['services'].map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}`} alt="usluga"/>
                                                </div>
                                                <div className="content">
                                                    <h3><a href="/service-details">{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href={`/usluge${value.path}`}><span className="text">{translation['servicesDesc']['readMore']}</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>
                </div>
                {/* End Featured Service Area  */}


                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--38 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}


                {/* Start Service Area */}
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {translation['serviceList'].map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {getIcon(val.icon)}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default withTranslation()(CorporateBusiness);