import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {DE, GB, HR} from "country-flag-icons/react/3x2";
import {HashLink} from "react-router-hash-link";
import {withTranslation} from "react-i18next";


class HeaderFive extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            // console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }



        const { color , headerPosition, i18n, t } = this.props;

        const changeLanguageHandler = (lang) => {
            i18n.changeLanguage(lang)
        }

        let translation = t('navbar',{returnObjects: true})


        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-3 col-6">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            <img src="/assets/images/logo/logo.png" alt="LSM Prijevodi" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-9 col-6">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                            <li><Link to="/">{translation['homepage']}</Link></li>
                                            <li className="has-droupdown"><Link to="#">{translation['services']}</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/usluge/usmeno-prevodenje">{translation['spokenTranslation']}</Link>
                                                        <ul className="submenu">
                                                            <li><HashLink smooth to="/usluge/usmeno-prevodenje#konsekutivno">{translation['consecutive']}</HashLink></li>
                                                            <li><HashLink smooth to="/usluge/usmeno-prevodenje#simultano">{translation['simultaneous']}</HashLink></li>
                                                            <li><HashLink smooth to="/usluge/usmeno-prevodenje#saptano">{translation['whispered']}</HashLink></li>
                                                            <li><HashLink smooth to="/usluge/usmeno-prevodenje#na-daljinu">{translation['atDistance']}</HashLink></li>
                                                        </ul>
                                                    </li>
                                                    <li><Link to="/usluge/pismeni-prijevod">{translation['writtenTranslation']}</Link>
                                                        <ul className="submenu">
                                                            <li><HashLink smooth to="/usluge/pismeni-prijevod#koresponencija">{translation['writtenCorrespondence']}</HashLink></li>
                                                            <li><HashLink smooth to="/usluge/pismeni-prijevod#web-stranice">{translation['webPages']}</HashLink></li>
                                                            <li><HashLink smooth to="/usluge/pismeni-prijevod#knjige">{translation['books']}</HashLink></li>
                                                            {/*<li><Link to="/usluge/pismeni-prijevod#na-daljinu">Prevođenje na daljinu</Link></li>*/}
                                                        </ul>
                                                    </li>
                                                    <li><HashLink smooth to="/usluge/sudski-tumac#sudski-tumac">{translation['courtInterpreter']}</HashLink></li>
                                                    <li><HashLink smooth to="/usluge/lektura#lektura">{translation['proofReading']}</HashLink></li>
                                                    <li><HashLink smooth to="/usluge/mentorstvo#mentorstvo">{translation['mentorship']}</HashLink></li>
                                                </ul>
                                            </li>

                                            <li className="has-droupdown"><Link to="/o-meni">{translation['aboutMe']}</Link>
                                                <ul className="submenu">
                                                    <li><HashLink to="/o-meni#zivotopis">{translation['CV']}</HashLink></li>
                                                    <li><HashLink to="/o-meni#prijevodi">{translation['publishedTranslations']}</HashLink></li>
                                                    <li><HashLink to="/o-meni#obrazovanje">{translation['education']}</HashLink></li>
                                                    <li><HashLink to="/o-meni#konferencije">{translation['conferences']}</HashLink></li>
                                                </ul>
                                            </li>
                                            {/*<li><Link to="#">Područja rada</Link></li>*/}
                                            <li><Link to="/klijenti">{translation['clients']}</Link></li>
                                            <li><Link to="/preporuke">{translation['recommendations']}</Link></li>
                                            <li><Link to="/kontakt">{translation['contact']}</Link></li>
                                        </ul>
                                    </nav>

                                    <div className="header-btn languages">
                                        {/*<a className="rn-btn" href="https://themeforest.net/checkout/from_item/25457315?license=regular">*/}
                                        {/*    <span>buy now</span>*/}
                                        {/*</a>*/}
                                        <div className="d-flex flex-column mr-1 hide-when-mobile">
                                            <div><span>Lidija Šimunić Mesić, univ. spec. philol.</span></div>
                                            <div><a href="mailto:lidija.simunic.mesic@gmail.com">lidija.simunic.mesic@gmail.com</a></div>
                                        </div>
                                        <div className={`language ${i18n.language ==='hr' ? 'active' : ''}`} onClick={() => changeLanguageHandler("hr")}><HR/></div>
                                        {/*<div className={`language ${i18n.language ==='de' ? 'active' : ''}`} onClick={() => changeLanguageHandler("de")}><DE/></div>*/}
                                        {/*<div className={`language ${i18n.language ==='en' ? 'active' : ''}`} onClick={() => changeLanguageHandler("en")}><GB/></div>*/}
                                    </div>
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default withTranslation()(HeaderFive);