// React Required
import React, {Component, Suspense} from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import CorporateBusiness from './home/CorporateBusiness';

// Dark Home Layout
// Element Layout
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";


// Blocks Layout
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import UsmenoPrevodenje from "./elements/UsmenoPrevodenje";
import PismeniPrijevod from "./elements/PismeniPrijevod";
import SudskiTumac from "./elements/SudskiTumac";
import Lektura from "./elements/Lektura";
import Mentorstvo from "./elements/Mentorstvo";
import Klijenti from "./elements/Klijenti";

import i18n from './localization/i18n';
import {I18nextProvider} from "react-i18next";
import Preporuke from "./elements/Preporuke";

const Loader = () => <div></div>;

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness}/>
                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/usluge/usmeno-prevodenje`} component={UsmenoPrevodenje}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/usluge/pismeni-prijevod`} component={PismeniPrijevod}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/usluge/sudski-tumac`} component={SudskiTumac}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/usluge/lektura`} component={Lektura}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/usluge/mentorstvo`} component={Mentorstvo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/o-meni`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/klijenti`} component={Klijenti}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/kontakt`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/preporuke`} component={Preporuke}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Suspense fallback={<Loader/>}><Root/></Suspense>, document.getElementById('root'));
serviceWorker.register();