import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import TabTwo from "./tab/TabTwo";
import FooterTwo from "../component/footer/FooterTwo";
import {withTranslation} from "react-i18next";

class About extends Component {

    componentDidMount() {
        this.scrollToElement();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.hash !== prevProps.location.hash) {
            this.scrollToElement();
        }
    }

    scrollToElement = () => {
        const {hash} = window.location;
        if (hash) {
            const id = hash.replace('#', '');
            const elementToId = {"zivotopis": 0, "konferencije": 3, "prijevodi": 1, "obrazovanje": 2}
            //get all the children of the tab-style--1 class and sort them by id
            const elements = Array.from(document.querySelector('.tab-style--1').childNodes).sort((a, b) => (a.id > b.id) ? 1 : -1)


            const element = elements[elementToId[id]];
            if (element) {
                element.click();
            }
        }
    }

    render() {
        const {t} = this.props;
        let tr = t('aboutMe', {returnObjects: true});

        return (
            <React.Fragment>
                <PageHelmet pageTitle='O meni'/>

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>


                <div className="about-area about-position-top pb--240 flex-grow-1 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5 align-self-start pt--240">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.jpg"
                                             alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{tr['title']}</h2>
                                            {/*<p className="description">{description}</p>*/}
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo/>

            </React.Fragment>
        )
    }
}

export default withTranslation()(About);