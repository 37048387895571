import React from 'react'
import {withTranslation} from "react-i18next";
class CallAction  extends React.Component {
    render(){
        const {t, actionKey} = this.props;
        let tr = t(actionKey,{returnObjects: true})
        let caConstants = t('callToActionConstants',{returnObjects: true})
        return (
            <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center ">
                                <h3 className="service" dangerouslySetInnerHTML={{__html:caConstants['action']}}></h3>
                                <span>{caConstants['requirements']}</span>
                                <div className="service-call text-white flex-column d-flex font--15">
                                    {tr['callToAction']['requirementsList'].map((value, index) => (
                                        <span key={index}>{value}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(CallAction);