import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {withTranslation} from "react-i18next";

class TabsTwo extends Component{
    render(){
        const { t, tabStyle } = this.props;
        let tr = t('aboutMe', {returnObjects: true});
        let cvData = tr['tabs'];
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab id="zivotopis">{cvData['workExperienceTitle']}</Tab>
                                        <Tab>{cvData['translationsTitle']}</Tab>
                                        <Tab id="prijevodi">{cvData['educationAndTrainingTitle']}</Tab>
                                        <Tab id="konferencije">{cvData['conferencesTitle']}</Tab>
                                        {/*<Tab>{tab4}</Tab>*/}
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                {cvData['workExperience'].map((value, index) => (
                                                    <li key={index}>
                                                        <span dangerouslySetInnerHTML={{__html: value['period']}} className={"bolded"}></span>
                                                        <span> {value['description']}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                {cvData['translationsList'].map((value, index) => (
                                                    <li key={index}>
                                                        <span dangerouslySetInnerHTML={{__html: value['title']}} className={"bolded"}></span>
                                                        <span> {value['publisher']}, {value['year']}., {value['languagePair']}</span>
                                                    </li>
                                                )).reverse()}
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                {cvData['educationAndTraining'].map((value, index) => (
                                                    <li key={index}>
                                                         <span dangerouslySetInnerHTML={{__html: value['period']}} className={"bolded"}></span>
                                                         <span> {value['description']}</span>
                                                    </li>
                                                  ))}
                                           </ul>
                                       </div>
                                    </TabPanel>


                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                {cvData['conferencesList'].map((value, index) => (
                                                    <li key={index}>
                                                        <span dangerouslySetInnerHTML={{__html: value['date'] + " " + (value['location'] ? value['location'] : '')}} className={"bolded"}></span>
                                                        {/*<span> {value['location']}</span>*/}
                                                        <span> {value['event_name']}</span>
                                                    </li>
                                                )).reverse()}

                                            </ul>
                                        </div>
                                    </TabPanel>



                                    {/*<TabPanel>*/}
                                    {/*   <div className="single-tab-content">*/}
                                    {/*       <ul>*/}
                                    {/*           <li>*/}
                                    {/*               <a href="/service">Sr. Front-end Engineer<span> - Google</span></a> 2018 - Current*/}
                                    {/*           </li>*/}
                                    {/*           <li>*/}
                                    {/*               <a href="/service">Front-end Engineer<span> - Microsoft</span></a> 2017 - 2018*/}
                                    {/*           </li>*/}
                                    {/*           <li>*/}
                                    {/*               <a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014*/}
                                    {/*           </li>*/}
                                    {/*       </ul>*/}
                                    {/*   </div>*/}
                                    {/*</TabPanel>*/}

                                    {/*<TabPanel>*/}
                                    {/*   <div className="single-tab-content">*/}
                                    {/*       <ul>*/}
                                    {/*           <li>*/}
                                    {/*               <a href="/service">BSc In CSE<span> - ABC University, Los Angeles, CA</span></a> 2010*/}
                                    {/*           </li>*/}
                                    {/*           <li>*/}
                                    {/*               <a href="/service">Diploma in Computer Science<span> - Gamma Technical Institute</span></a> 2009*/}
                                    {/*           </li>*/}
                                    {/*           <li>*/}
                                    {/*               <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007*/}
                                    {/*           </li>*/}
                                    {/*       </ul>*/}
                                    {/*   </div>*/}
                                    {/*</TabPanel>*/}
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default withTranslation()(TabsTwo);