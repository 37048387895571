import React from 'react'
import {useTranslation, withTranslation} from "react-i18next";
class CallAction extends React.Component {


    render() {
        const { t } = this.props;
        let translation = t('homepage',{returnObjects: true})
        return (<div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h2>LSM prijevodi</h2>
                            <span>Lidija Šimunić Mesić, univ. spec. philol. </span>
                            <span>Mail: <a
                                href="mailto:lidija.simunic.mesic@gmail.com">lidija.simunic.mesic@gmail.com</a> </span>
                            <span>Tel: <a href="tel:+38598406413">+385 98 406 413</a></span>
                            <a className="rn-button-style--2" href="#"><span>{translation['contactUs']}</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default withTranslation()(CallAction);