import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";


import FooterTwo from "../component/footer/FooterTwo";
import Header from "../component/header/HeaderFive";

import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from "react-accessible-accordion";
import {withTranslation} from 'react-i18next';

class Klijenti extends Component {
    render() {

        const { t } = this.props;
        const clientsData = t('clientsData', {returnObjects: true})

        return (
            <React.Fragment>
                <PageHelmet pageTitle='Klijenti'/>

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Blog Area */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">{clientsData['recommendationsTitle']}</h2>
                                    <p>{clientsData['recommendationsDesc']}</p>
                                </div>
                            </div>

                            <div className="col-lg-9 pl--60-no-mobile">
                                <div className="row">
                                    <Accordion className="accodion-style--1" allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={Array.from(Array(clientsData['recommendations'].length).keys())}>
                                        {clientsData['recommendations'].map((val, i) => (
                                            <AccordionItem key={i} uuid={i}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="rn-accordition-title">
                                                            <h4 className="">{val.author}</h4>
                                                            <p>{val.title}</p>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="rn-accordition-inner">
                                                        <p>{val.text}</p>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo/>

            </React.Fragment>
        )
    }
}

export default withTranslation()(Klijenti);
