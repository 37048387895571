import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";

import FooterTwo from "../component/footer/FooterTwo";
import Header from "../component/header/HeaderFive";
import {withTranslation} from 'react-i18next';

class Klijenti extends Component {
    render() {
        const {t} = this.props;
        const clientsData = t('clientsData', {returnObjects: true})

        return (
            <React.Fragment>
                <PageHelmet pageTitle='Klijenti'/>

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                <div className="rn-featured-service-area pt--90 flex-grow-1 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">{clientsData['clientsTitle']}</h2>
                                    <p>{clientsData['clientsDesc']}</p>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    {clientsData['clients'].map((val, i) => (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                            <div className="featured featured--2 text-center">
                                                <h5>{val}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo/>

            </React.Fragment>
        )
    }
}

export default withTranslation()(Klijenti);
