import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend) // Load translations using http backend
    .use(LanguageDetector) // Detect language
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        fallbackLng: 'hr', // Use English if detection fails
        debug: false,
        detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false // React already safes from XSS
        }
    });

export default i18n;
